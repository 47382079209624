.settingsTiles {
    border-radius: 10px !important;
    font-size: 18px !important;
    font-weight: 600 !important;
    width: calc((100vw - 250px - 7rem) / 4);
    // box-shadow: #0000001f 0 0 20px -3px;
    box-shadow: rgba(0, 0, 0, 0.05);
    // min-height: calc((100vh - 7rem) / 5);
    height: 100%;
    min-height: 121px;
    cursor: pointer;

    .ant-card-body {
        padding: 20px;
    }

    svg {
        width: 40px;
        height: 40px;
    }
}

.settingWrapper {
    max-height: calc(100vh - 73px - 3rem);
    overflow: auto;
    background-color: white;
    padding: 1rem 2rem;
    border-radius: 10px;
}

#accountSettings .ant-upload.ant-upload-select-picture-card {
    height: auto !important;
    width: 100% !important;
}

#accountSettings,
#deliverySettings,
#termsWrapper,
#bankAccountSettings {
    // height: 100%;
    display: flex;
    flex-direction: column;
    background-color: white;
    padding: 1rem 2rem;
    border-radius: 10px;

    form {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 1;
    }
}

#termsWrapper {
    max-height: calc(100vh - 73px - 3rem);
    overflow-y: auto;

}

.termsAndConditions {

    h3 {
        font-weight: bold;
    }

    h4 {
        font-weight: bold;
    }
}

.settingTitle {
    font-size: 30px;
    font-weight: 500;
    line-height: 45px;
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.textBtn {
    margin-top: 2rem;
    font-weight: 500 !important;
    padding-left: 0;
}

.faqTitle {
    font-size: 16px;
    line-height: 24px;

}

#deliverySettings {
    display: flex;
    flex-direction: column;
    padding: 1rem 2rem;
    // height: 100%;
}

.contactUsDiv {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: flex-end;
}

.couponListWrapper {
    .ant-list-split .ant-list-item {
        border-bottom: none !important;
    }

    .ant-list-item {
        padding: 0 !important;
    }
}

.couponListTitle,
.customTiming {
    line-height: 30px;
    // background-color: #F4F4F4;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
    border-radius: 10px !important;
    padding: 1rem 1rem;
}

.customTiming:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.couponListSubItems {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // flex: 1;
}

.customTimeSubItems {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    // flex: 1;
}

.customTime {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1;
}

.couponListText {
    font-size: 20px;
    font-weight: 400;
    // flex: 1;

}

.customTimeText {
    font-size: 16px;
    font-weight: 500;
    color: rgb(17, 17, 17);
    margin: 14px 2px 2px 10px;

}

.timemid {
    font-size: 16px;
    font-weight: 400;
    color: rgb(17, 17, 17);
    margin: 14px 20px 2px -8px;
}

.couponListSubText {
    font-size: 10px !important;
    line-height: 15px !important;
}

.couponTitle {
    font-size: 20px;
    font-weight: 500;
    justify-content: space-between;
    padding: 0.5rem;
    padding-top: 0;
    border-bottom: 1px solid #F0F0F0;
    margin-bottom: 0.5rem;

    .ant-btn>.anticon {
        line-height: 0 !important;
    }
}

.addCouponCircleBtn {
    justify-content: center;
    color: #fff !important;
    border-color: @primary-color  !important;
    background-color: @primary-color  !important;
}

.shopProfileImage {
    width: 100px !important;
    height: 100px !important;

}

.accountTitle {
    margin-bottom: 1rem;
    background-color: white;
    padding: 1rem;
    border-radius: 10px;
    line-height: 30px;
}

.settingCompWrapper {
    max-height: calc(100vh - 73px - 3rem);
}

.addCouponForm {
    display: flex;
    flex-wrap: wrap;

    .addCouponFormFirstDiv {
        flex: 1;
        margin-right: 2rem;
    }
}

.deliveryInputSpace {
    align-items: flex-start;

    label {
        white-space: nowrap;
    }

    .ant-form-item-control {
        min-width: 200px;
    }
}

.borderBottom {
    padding-bottom: 0.5rem;
    border-bottom: 1px solid #F0F0F0;
}

.profileUploader {
    width: 35% !important;
    max-width: 370px;
    margin-right: -2rem;
    justify-content: center;
}

.accountForm {

    .ant-space-item {
        padding-bottom: 0 !important;
    }

    .formItem {
        width: calc(50% - 1rem);
        margin-right: 1rem !important;
        max-width: 330px !important;
    }
}

.rightBorder {
    border-right: 1px solid #F0F0F0;
}

#couponList {
    background-color: white;
    border-radius: 10px;
    padding: 1.5rem 2rem;
    padding-bottom: 0.5rem;
}

#defaultStoreTime {
    align-items: center;
    background-color: white;
    border-radius: 10px;
    text-align: center;
    padding: 1rem 2rem;
    margin-bottom: 1rem;
    width: calc(100%);

    h3 {
        font-size: 18px;
        margin-bottom: 0px;
        margin-top: 2px;
    }
}

.storeTimingButton {
    position: absolute;
    margin-top: 0rem;
    right: 0px;
    bottom: 1rem;
}

#paymentComp {
    max-height: calc(100vh - 73px - 4rem);
    background-color: white;
    border-radius: 10px;
    padding: 2rem;
    display: flex;
    flex-direction: column;
}

.paymentCardTitle {
    font-size: 17px;
}

.paymentCardAmount {
    font-size: 23px;
    font-weight: 500;
}

.rightPaymentDiv {
    border-right: solid 1px #f4f4f4;
}

.payOverviewCard {
    background-color: @layout-header-background  !important;
    flex: 1;
    color: white !important;
    border-radius: 10px !important;
}

.bankCard {
    background-color: #f4f4f4 !important;
    border-radius: 10px !important;

    .ant-card-body {
        display: flex;
        min-width: 250px;
        padding-left: 1rem;
    }
}

.ant-form-item-label {
    font-weight: 400 !important;
}

label .ant-form-item {
    font-weight: 400 !important;
}

.labelClass {
    font-weight: 400 !important;

}

.paymentAddBankBtn {
    background-color: #f4f4f4 !important;
    height: 100px !important;
    width: 200px !important;
    border-radius: 10px !important;
}

button .paymentAddBankBtn {

    &:hover,
    &:focus {
        background-color: #f4f4f4 !important;
    }
}

.verifiedCheck {
    span {
        background-color: green;
        color: white;
        padding: 4px;
        border-radius: 100%;
        font-size: 10px;
        margin: 0 0.5rem;
    }
}

#couponForm {
    .ant-form-item {
        width: 240px !important;
    }
}

#bankAccountSettings {
    .ant-form-item {
        width: 240px !important;
    }
}

#bankSettings {
    .ant-form-item {
        width: 210px !important;
    }

    .ant-select-single .ant-select-selector .ant-select-selection-search {
        left: 0 !important;
    }
}

.transactionList {
    overflow-y: auto;
}

// address css
.address__Card {
    border-radius: 10px !important;
    color: @primary-color  !important;
    max-width: 265px;
    margin: 0.5rem !important;
}

.mapModal {
    width: unset !important;

    .ant-modal-body {
        padding: 0 !important;
    }
}
@primary-color: #29bb89;@layout-header-background: #090552;