
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected{
    background-color: unset !important;
    border-right: 5px solid @primary-color !important;
}
.ant-menu-dark .ant-menu-item-selected .anticon + span{
    color: @primary-color !important;
    border-right: 5px solid @primary-color !important;
}
.ant-menu-dark .ant-menu-item-selected a{
    color: @primary-color !important;
}

.ant-menu-dark .ant-menu-item-selected path{
    fill: @primary-color !important;
}

.ant-menu-item span{
    font-weight: 400 !important;
}
.ant-menu-item-selected{
    font-weight: 600 !important;
}

// .ant-switch-handle::before{
//     background-color: @primary-color !important;
// }

// .ant-switch{
//     background-color: #F4F4F4 !important;
// }
// .ant-switch-checked{
//     background-color: rgba(@primary-color , 0.2) !important;    
// }

.ant-input, .ant-input-group-addon:first-child, .ant-select:not(.ant-select-customize-input) .ant-select-selector, .ant-input-affix-wrapper, .ant-input-number{
    border-left: none !important;
    border-right: none !important;
    border-top: none !important;
    background-color: transparent;
}

.ant-upload-list-picture-card .ant-upload-list-item{
    border: none !important;
}
.ant-upload-list-picture-card .ant-upload-list-item-info{
    border-radius: 100%;
}
.ant-upload.ant-upload-select-picture-card{
    border: none !important;
    background-color: transparent !important;
}
.ant-upload-list-picture-card-container {
    margin: 0 0 8px 0 !important;
}
.ant-input:focus, .ant-input-focused, .ant-input-number:focus, .ant-input-number-focused, .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector, .ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused{
    box-shadow: unset !important;
}
.ant-list-item{
    padding: 10px 0 !important;
}
.ant-form-large .ant-form-item-label > label{
    font-weight: 400;
}
.ant-empty-normal{
    margin: 0 !important;
}
.ant-input, .ant-select:not(.ant-select-customize-input) .ant-select-selector, .ant-input-number{
    border-color: #F0F0F0 !important;
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu{
    margin-top: 0 !important;
}
.ant-form-item-label > label{
    font-weight: 500;
}
.ant-modal-header{
    background-color: @layout-header-background !important;
    border-radius: 7px !important;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    .ant-modal-title{
        color: white !important;
    }
}
.ant-modal-content{
    border-radius: 10px !important;
    .ant-modal-close-x{
        svg{
            color: white !important;
        }
    }
}